<template>
  <div>
    <h2 class="mb-4">Список подборок</h2>

    <data-table ref="dataTable" url="compilations" :headers="headers">
      <template v-slot:[`item.url`]="{ item: compilation }">
        <a :href="createFullLink(compilation.url)" target="_blank">{{ compilation.url }}</a>
      </template>

      <template v-slot:[`item.isIndexable`]="{ item: compilation }">{{ compilation.seo.isIndexable | yesNo }}</template>

      <template v-slot:[`item.isPublished`]="{ item: compilation }">{{ compilation.isPublished | yesNo }}</template>

      <template v-slot:[`item.actions`]="{ item: compilation }">
        <nobr>
          <v-btn class="mr-2" icon color="accent" :to="{ name: 'compilations-id', params: { id: compilation.id } }">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon color="error" @click="deleteCompilation(compilation)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </nobr>
      </template>
    </data-table>
  </div>
</template>

<script>
import { Compilation } from "~/libs/compilation/compilation";
import { ApiValidationError } from "~/core/api-validation-error";

export default {
  data() {
    return {
      headers: [
        { text: "Название", value: "title" },
        { text: "URL", value: "url" },
        { text: "Индексация", value: "isIndexable", sortable: false },
        { text: "Опубликовано", value: "isPublished" },
        { text: "Действия", value: "actions", sortable: false }
      ]
    };
  },
  methods: {
    createFullLink(url) {
      return Compilation.createFullUrl(url);
    },

    async deleteCompilation({ id }) {
      try {
        await this.$axios.$delete(`compilations/${id}`);
        await this.$refs.dataTable.update();

        this.$snackbar.success("Удалено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не удалено" + html);
      }
    }
  }
};
</script>
